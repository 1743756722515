@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';
body {
	margin: 0;
	padding: 0;
}
.ant-upload-list-item-error {
	border-color: #d9d9d9 !important;
}
